import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';
import { EntryFragment } from '@/fragments';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import {
  createDecoratedEntry,
  getEntryInstances,
} from '@/pages/Schedule/utils';
import { createEntryQueryKey } from '@/utils/queryKeys';
import type {
  GetEntryForMessageQuery,
  GetEntryForMessageQueryVariables,
} from './useEntry.generated';

const query = gql`
  ${EntryFragment}
  query GetEntryForMessage($scheduleId: ID!, $entryId: ID!) {
    getEntry(scheduleId: $scheduleId, entryId: $entryId) {
      ...Entry
    }
  }
`;

type Data = {
  entry: DecoratedEntry;
  entryInstanceForMessageInstance?: DecoratedInstance;
};

export const useEntry = (entryId: string, messageInstanceDate: DateTime) => {
  const { scheduleId, timeZone: scheduleTimeZone } = useScheduleContext();

  const selector = useCallback(
    (data: GetEntryForMessageQuery): Data => {
      const entry = createDecoratedEntry(data.getEntry, scheduleTimeZone);
      const entryInstanceForMessageInstance = getEntryInstances(
        entry,
        messageInstanceDate,
        messageInstanceDate,
        scheduleTimeZone
      )[0];
      return {
        entry,
        entryInstanceForMessageInstance,
      };
    },
    [messageInstanceDate, scheduleTimeZone]
  );

  return useQuery({
    queryKey: createEntryQueryKey(scheduleId, entryId),
    select: selector,
    queryFn: () => {
      return gqlClient.request<
        GetEntryForMessageQuery,
        GetEntryForMessageQueryVariables
      >(query, { scheduleId, entryId });
    },
  });
};
