import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCalendar3, BsChat } from 'react-icons/bs';
import { HiOutlineUser } from 'react-icons/hi';
import { IoWarning } from 'react-icons/io5';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import {
  useDeleteEntryMessage,
  useScheduleMembers,
} from '@/pages/Schedule/hooks';
import { summarizeMessageSendDate } from '@/pages/Schedule/utils';
import { colors } from '@/theme';
import { MessageStatusType } from '@/types/gql.generated';
import {
  Badge,
  Box,
  Button,
  Flex,
  type FlexProps,
  useDisclosure,
  useToken,
} from '@/ui';
import type { EntryMessage } from '../../hooks/useEntryMessages';
import { EditEntryMessageModal } from '../EditEntryMessageModal';
import { DeliveryFailures } from './components/DeliveryFailures';

type Props = FlexProps & {
  showStatus?: boolean;
  message: EntryMessage;
};

export const Message = ({ message, showStatus, ...props }: Props) => {
  const { deleteEntryMessage } = useDeleteEntryMessage(message.entry.id);
  const { members } = useScheduleMembers();
  const { t } = useTranslation('scheduleMessages');
  const warningIconColor = useToken('colors', 'red.600');
  const [showDeliveryFailures, setShowDeliveryFailures] = useState(false);
  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: openDeleteConfirm,
    onClose: closeDeleteConfirm,
  } = useDisclosure();

  const {
    isOpen: isEditModalOpen,
    onOpen: openEditModal,
    onClose: closeEditModal,
  } = useDisclosure();

  const canModifyMessage = message.status === MessageStatusType.Scheduled;

  let recipients = '';
  if (message.recipients.length === 0) {
    recipients =
      message.status === MessageStatusType.Scheduled && members.length
        ? t('everyone', { count: members.length })
        : t('everyone_one');
  } else if (message.recipients.length === 2) {
    recipients = message.recipients.map(({ name }) => name).join(' and ');
  } else {
    recipients = message.recipients.map((r) => r.name).join(', ');
  }

  const failedDeliveryAttempts = message.deliveryAttempts.filter(
    (attempt) => attempt.success === false
  );

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('delete_confirm.confirm')}
        isOpen={isDeleteConfirmOpen}
        message={`"${message.body}"`}
        title={t('delete_confirm.title')}
        onCancel={closeDeleteConfirm}
        onConfirm={() => {
          deleteEntryMessage(message.id, {
            onSuccess: closeDeleteConfirm,
          });
        }}
      />

      {isEditModalOpen && (
        <EditEntryMessageModal
          entryId={message.entry.id}
          message={message}
          onCancel={closeEditModal}
          onSaveSuccess={() => closeEditModal()}
        />
      )}

      <Flex
        {...props}
        data-entry-id={message.entry.id}
        data-message-id={message.id}
        data-send-at={message.sendAt.toISO()}
        direction={{ base: 'column', md: 'row' }}
      >
        <Box flex="1" mr={{ base: 0, md: 10 }}>
          <Box
            bg="customgray.dark"
            borderRadius="25px 25px 0 25px"
            color="gray.100"
            overflowWrap="anywhere"
            p="8"
            whiteSpace="pre-wrap"
          >
            {message.body}
          </Box>
          <Flex align="center" mt="3">
            {(showStatus || message.status === MessageStatusType.Failed) && (
              <Badge
                mr="3"
                colorScheme={
                  message.status === MessageStatusType.Scheduled
                    ? 'yellow'
                    : message.status === MessageStatusType.Sent
                      ? 'green'
                      : message.status === MessageStatusType.Failed
                        ? 'red'
                        : 'blue'
                }
              >
                {message.status}
              </Badge>
            )}
            {failedDeliveryAttempts.length > 0 && (
              <Flex align="center" mr="3">
                <IoWarning color={warningIconColor} />
                <Button
                  _hover={{ textDecoration: 'underline' }}
                  color="red.600"
                  fontWeight="bold"
                  ml="1"
                  size="sm"
                  variant="link"
                  onClick={() => setShowDeliveryFailures((value) => !value)}
                >
                  {t('delivery_failures', {
                    count: failedDeliveryAttempts.length,
                  })}
                </Button>
              </Flex>
            )}
            {canModifyMessage && (
              <>
                <Button
                  color="customgray.mid"
                  colorScheme="dark"
                  mr="3"
                  size="sm"
                  variant="link"
                  onClick={openEditModal}
                >
                  {t('edit_action')}
                </Button>
                <Button
                  color="customgray.mid"
                  colorScheme="dark"
                  size="sm"
                  variant="link"
                  onClick={openDeleteConfirm}
                >
                  {t('delete_action')}
                </Button>
              </>
            )}
          </Flex>

          {showDeliveryFailures && (
            <DeliveryFailures
              attempts={message.deliveryAttempts}
              onClose={() => setShowDeliveryFailures(false)}
            />
          )}
        </Box>

        <Box
          ml={{ base: 2, md: 0 }}
          mt={{ base: 3, md: 0 }}
          w={{ base: 'auto', md: '250px' }}
        >
          <Flex align="flex-start" fontSize="sm">
            <Box mr="5" mt="3px">
              <BsCalendar3 color={colors.customgray.mid} size="14" />
            </Box>
            {message.entry.title || t('untitled_entry', { ns: 'common' })} on{' '}
            {message.entry.recurrences[0].isOnDay
              ? message.instance.toFormat('MMM, d yyyy')
              : message.instance.toFormat('MMM, d yyyy')}
          </Flex>

          <Flex align="flex-start" fontSize="sm" mt="2">
            <Box mr="5" mt="3px">
              <BsChat color={colors.customgray.mid} size="14" />
            </Box>
            <Box>{summarizeMessageSendDate(message)}</Box>
          </Flex>

          <Flex align="flex-start" fontSize="sm" mt="2">
            <Box mr="5" mt="3px">
              <HiOutlineUser color={colors.customgray.mid} size="14" />
            </Box>
            {recipients}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
