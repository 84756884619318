import { useTranslation } from 'react-i18next';
import { AppLoader } from '@/components/AppLoader';
import {
  type EntryModalMessage,
  EntryMessageForm,
} from '@/pages/Schedule/components';
import { Box, Modal, ModalContent, ModalOverlay, Text } from '@/ui';
import { useEntry } from './hooks/useEntry';

type Props = {
  entryId: string;
  message: EntryModalMessage;
  onSaveSuccess: (message: EntryModalMessage) => void;
  onCancel: () => void;
};

export const EditEntryMessageModal = ({
  entryId,
  message,
  onSaveSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation('scheduleMessages');
  const { data, isLoading } = useEntry(entryId, message.instance);
  const { entry, entryInstanceForMessageInstance } = data ?? {};

  return (
    <Modal isCentered isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: '100vw', md: 'auto' }}
        minH={{ base: 'var(--app-height)', md: 'auto' }}
        minW={{ base: '100vw', md: '600px' }}
        p="8"
        w="auto"
      >
        {isLoading ? (
          <AppLoader minH="500px" />
        ) : entry ? (
          <>
            <Text fontSize="xl" fontWeight="medium" lineHeight="normal" mb="4">
              {message
                ? t('edit_message_modal.title.edit', {
                    title: entry.title || t('untitled_entry', { ns: 'common' }),
                  })
                : t('edit_message_modal.title.create', {
                    title: entry.title || t('untitled_entry', { ns: 'common' }),
                  })}
            </Text>

            <EntryMessageForm
              entry={entry}
              instance={entryInstanceForMessageInstance}
              message={message}
              onCancel={onCancel}
              onSaveSuccess={onSaveSuccess}
            />
          </>
        ) : (
          <Box>{t('edit_message_modal.entry_not_found')}</Box>
        )}
      </ModalContent>
    </Modal>
  );
};
