import { AnimatePresence } from 'framer-motion';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { ScheduleContent } from '@/pages/Schedule/components';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { Button, Flex, forwardRef } from '@/ui';
import { MessagesEmptyState } from './components/EmptyState';
import { MembersSection } from './components/MembersSection';
import { MessageList } from './components/MessageList';
import { Sidebar } from './components/Sidebar';
import { SortDirectionMenu } from './components/SortDirectionMenu';
import { useEntryMessages } from './hooks/useEntryMessages';
import type { ScheduleMessagesView } from './types';

export const ScheduleMessages = () => {
  const { view } = useParams<{ view: ScheduleMessagesView }>();
  const { data, isLoading, isError, sortDirection, setSortDirection } =
    useEntryMessages();
  const isLgBreakpoint = useIsBreakpoint('lg');
  const { hasSchedulePermission } = useSchedulePermissions();
  const { t } = useTranslation('scheduleMessages');
  const navigate = useNavigate();
  usePageTitle(t('page_title'));
  useTrackPageView('schedule:messages');

  if (!hasSchedulePermission('MESSAGES')) {
    return <Navigate to="/" />;
  }

  if (isLoading || !data) {
    return (
      <Container>
        <AppLoader />
      </Container>
    );
  }

  if (isError) {
    return <Container>{t('error')}</Container>;
  }

  const handleAddMessage = () => {
    navigate('../..', {
      state: {
        launch: 'entry-modal:create-message',
      },
    });
  };

  const sortControl =
    view === 'recipients' ? null : (
      <SortDirectionMenu selected={sortDirection} onChange={setSortDirection} />
    );

  return (
    <Container
      headingRightAccessory={
        isLgBreakpoint ? <Flex gap="2">{sortControl}</Flex> : undefined
      }
    >
      {sortControl && data.all.length > 0 && (
        <Flex
          display={{ base: 'none', lg: 'flex' }}
          gap="2"
          justify="flex-end"
          mb="8"
        >
          {sortControl}
          <Button onClick={handleAddMessage}>{t('add_a_message')}</Button>
        </Flex>
      )}

      <AnimatePresence mode="popLayout">
        {view === 'scheduled' && (
          <MotionContainer key="scheduled">
            {data.scheduled.length ? (
              <MessageList data={data.scheduled} />
            ) : (
              <MessagesEmptyState onAddMessage={handleAddMessage} />
            )}
          </MotionContainer>
        )}
        {view === 'sent' && (
          <MotionContainer key="sent">
            {data.sent.length ? (
              <MessageList data={data.sent} />
            ) : (
              <MessagesEmptyState
                title={t('empty_state.sent_title')}
                onAddMessage={handleAddMessage}
              />
            )}
          </MotionContainer>
        )}
        {view === 'all' && (
          <MotionContainer key="all">
            {data.all.length ? (
              <MessageList data={data.all} showStatus />
            ) : (
              <MessagesEmptyState onAddMessage={handleAddMessage} />
            )}
          </MotionContainer>
        )}
        {view === 'recipients' && (
          <MotionContainer key="recipients">
            <MembersSection />
          </MotionContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

const MotionContainer = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    ref={ref}
    {...props}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    flex="1"
    initial={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
  />
));

const Container = ({
  children,
  headingRightAccessory,
}: {
  children: ReactNode;
  headingRightAccessory?: ReactNode;
}) => (
  <ScheduleContent direction={{ base: 'column', lg: 'row' }}>
    <Sidebar
      headingRightAccessory={headingRightAccessory}
      mb={{ base: 10, lg: 0 }}
      mr={{ base: 0, lg: 10 }}
      pr={{ base: 0, lg: 10 }}
    />

    <Flex direction="column" flex="1">
      {children}
    </Flex>
  </ScheduleContent>
);
