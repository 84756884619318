import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { TbArrowsSort } from 'react-icons/tb';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { i18n } from '@/i18n';
import { SortDirection } from '@/types/gql.generated';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useToken,
} from '@/ui';

const sortDirectionLabel = {
  [SortDirection.Desc]: i18n.t('scheduleMessages:sort.desc'),
  [SortDirection.Asc]: i18n.t('scheduleMessages:sort.asc'),
};

type Props = {
  selected: SortDirection;
  onChange: (direction: SortDirection) => void;
};

export const SortDirectionMenu = ({ selected, onChange }: Props) => {
  const [darkgray] = useToken('colors', ['customgray.dark']);
  const { t } = useTranslation('scheduleMessages');
  const isMobileBreakpoint = useIsMobileBreakpoint();

  return (
    <Menu placement="bottom-end">
      <MenuButton
        aria-label={t('sort.label')}
        as={Button}
        rightIcon={<ChevronDownIcon color={darkgray} h={5} w={5} />}
        variant="secondary"
        leftIcon={
          isMobileBreakpoint ? undefined : <TbArrowsSort color={darkgray} />
        }
      >
        {sortDirectionLabel[selected]}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={SortDirection.Desc}
          type="radio"
          onChange={(direction) => onChange(direction as SortDirection)}
        >
          <MenuItemOption value={SortDirection.Desc}>
            {sortDirectionLabel[SortDirection.Desc]}
          </MenuItemOption>
          <MenuItemOption value={SortDirection.Asc}>
            {sortDirectionLabel[SortDirection.Asc]}
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
