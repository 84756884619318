import type { ReactNode } from 'react';
import { Flex, Heading, Text } from '@/ui';

type Props = {
  image: ReactNode;
  title: string;
  byline: ReactNode;
  children?: ReactNode;
};

export const EmptyState = ({ image, title, byline, children }: Props) => {
  return (
    <Flex
      align="center"
      direction="column"
      flex="1"
      mt={{ lg: '100px' }}
      textAlign="center"
    >
      {image}

      <Heading
        fontSize={{ base: 'xl', md: '2xl', lg: '32px' }}
        fontWeight="bold"
      >
        {title}
      </Heading>

      <Text fontSize={{ base: 'md', lg: 'lg' }} mb="6" mt="4">
        {byline}
      </Text>

      {children}
    </Flex>
  );
};
