import { useTranslation } from 'react-i18next';
import type { GetScheduleMembersQuery } from '@/pages/Schedule/hooks';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/ui';
import { MoreMenu } from './MoreMenu';

type Props = {
  members: GetScheduleMembersQuery['getSchedule']['members'];
};

// TODO this won't format international phone numbers correctly
function formatPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumber;
}

export const MembersTable = ({ members }: Props) => {
  const { t } = useTranslation('members');

  return (
    <Table
      colorScheme="gray"
      sx={{
        'th, td': {
          paddingX: { sm: 2, md: 6 },
          paddingY: { sm: 2, md: 5 },
          borderColor: 'gray.100',
        },
        th: {
          color: 'customgray.dark',
          fontWeight: '500',
          fontSize: 'md',
          textTransform: 'none',
          letterSpacing: 'inherit',
          paddingTop: 0,
        },
        'td:first-of-type, th:first-of-type': {
          paddingLeft: 0,
        },
        'td:last-of-type, th:last-of-type': {
          textAlign: 'right',
          paddingRight: 0,
        },
        'tr:last-of-type td': {
          borderBottom: 0,
          paddingBottom: 0,
        },
      }}
    >
      <Thead>
        <Tr>
          <Th>{t('members_table.name')}</Th>
          <Th>{t('members_table.phone')}</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {members?.map((member) => (
          <Tr key={member.id}>
            <Td>{member.name}</Td>
            <Td>{formatPhoneNumber(member.phoneNumber)}</Td>
            <Td>
              <MoreMenu member={member} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
