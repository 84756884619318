import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import {
  type GetScheduleMembersQuery,
  useRemoveMember,
} from '@/pages/Schedule/hooks';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@/ui';

type Props = {
  member: Exclude<
    GetScheduleMembersQuery['getSchedule']['members'],
    undefined | null
  >[number];
};

export const MoreMenu = ({ member }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeMember, isPending } = useRemoveMember();
  const { t } = useTranslation('members');
  const {
    isOpen: isRemoveConfirmOpen,
    onOpen: openRemoveConfirm,
    onClose: closeRemoveConfirm,
  } = useDisclosure();

  const handleConfirm = () => {
    removeMember(member.id, {
      onSuccess: () => closeRemoveConfirm(),
    });
  };

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('confirm_remove.confirm')}
        isLoading={isPending}
        isOpen={isRemoveConfirmOpen}
        title={t('confirm_remove.title', { name: member.name })}
        onCancel={closeRemoveConfirm}
        onConfirm={handleConfirm}
      />
      <Menu isLazy isOpen={isOpen} placement="bottom-end" onClose={onClose}>
        <MenuList>
          <MenuItem onClick={openRemoveConfirm}>
            {t('more_menu.remove')}
          </MenuItem>
        </MenuList>
        <MenuButton
          aria-label={t('more_menu.aria_label')}
          as={Button}
          colorScheme="dark"
          minW="auto"
          px="2"
          variant="ghost"
          onClick={onOpen}
        >
          <FiMoreHorizontal size={24} />
        </MenuButton>
      </Menu>
    </>
  );
};
