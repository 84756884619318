import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUsers } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeading } from '@/pages/Schedule/components';
import { useScheduleMembersLimit } from '@/pages/Schedule/hooks';
import { Button, type ButtonProps, Divider, Flex, type FlexProps } from '@/ui';
import type { ScheduleMessagesView } from '../../types';

const NavItem = (props: ButtonProps) => {
  return (
    <Button
      alignItems="center"
      color="gray.500"
      fontSize={{ base: 'sm', md: 'lg' }}
      fontWeight="medium"
      h="auto"
      lineHeight="normal"
      minW="auto"
      mr={{ base: 2, lg: 0 }}
      my="1"
      overflow="hidden"
      pl={{ base: 2, md: 3 }}
      pr={{ base: 2, md: 3 }}
      py={{ base: 2, md: 3 }}
      textAlign="left"
      textOverflow="ellipsis"
      variant="unstyled"
      whiteSpace="nowrap"
      _active={{
        bg: 'gray.100',
        color: 'customgray.dark',
      }}
      _hover={{
        bg: 'gray.100',
        color: 'customgray.dark',
      }}
      sx={{
        span: {
          position: 'relative',
          top: '2px',
          mr: 3,
        },
      }}
      {...props}
    />
  );
};

type Props = FlexProps & {
  headingRightAccessory?: ReactNode;
};

export const Sidebar = ({ headingRightAccessory, ...props }: Props) => {
  const { view } = useParams<{ view: ScheduleMessagesView }>();
  const membersLimit = useScheduleMembersLimit();
  const navigate = useNavigate();
  const { t } = useTranslation('scheduleMessages');

  return (
    <Flex
      borderRightColor="gray.100"
      borderRightWidth={{ base: 0, lg: '1px' }}
      direction="column"
      minW={{ base: 'auto', xl: '350px' }}
      {...props}
    >
      <PageHeading
        mb={{ base: 6, lg: 8 }}
        rightAccessory={headingRightAccessory}
      >
        {t('heading')}
      </PageHeading>

      <Flex direction={{ base: 'row', lg: 'column' }}>
        <NavItem
          isActive={view === 'recipients'}
          leftIcon={<FiUsers />}
          onClick={() => navigate('../recipients')}
        >
          Recipients
          {membersLimit && membersLimit.members > 0 && (
            <Flex
              align="center"
              bottom="0"
              color="gray.400"
              display={{ base: 'none', lg: 'flex' }}
              fontSize="sm"
              pos="absolute"
              right={{ base: 2, md: 3 }}
              top="0"
            >
              {membersLimit.members}/{membersLimit.limit}
            </Flex>
          )}
        </NavItem>

        <Divider display={{ base: 'none', lg: 'block' }} my="5" />

        <NavItem isActive={view === 'all'} onClick={() => navigate('../all')}>
          {t('nav.all')}
        </NavItem>
        <NavItem
          isActive={view === 'scheduled'}
          onClick={() => navigate('../scheduled')}
        >
          {t('nav.scheduled')}
        </NavItem>
        <NavItem isActive={view === 'sent'} onClick={() => navigate('../sent')}>
          {t('nav.sent')}
        </NavItem>
      </Flex>
    </Flex>
  );
};
