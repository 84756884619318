import orderBy from 'lodash.orderby';
import { ImCheckmark } from 'react-icons/im';
import { IoMdCloseCircle } from 'react-icons/io';
import type { DeliveryAttempt } from '@/types/gql.generated';
import {
  Box,
  type BoxProps,
  CloseButton,
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToken,
} from '@/ui';

type Props = BoxProps & {
  attempts: DeliveryAttempt[];
  onClose: () => void;
};

const ErrorCodeLookup: Record<string, string> = {
  '30001': 'Queue overflow',
  '30002': 'Account suspended',
  '30003': 'Unreachable destination',
  '30004': 'Message blocked',
  '30005': 'Unknown destination',
  '30006': 'Landline or unreachable carrier',
  '30007': 'Message filtered',
  '30008': 'Unknown error',
  '30009': 'Missing inbound segment',
};

export const DeliveryFailures = ({ attempts, onClose }: Props) => {
  const [success, failure] = useToken('colors', ['green.500', 'red.500']);
  const sortedAttempts = orderBy(attempts, 'status');

  return (
    <Box bg="gray.100" borderRadius="md" mt="4" p="3">
      <Flex align="center">
        <Text flex="1" fontWeight="medium">
          Delivery status
        </Text>
        <CloseButton onClick={onClose} />
      </Flex>
      <Table
        mt="3"
        size="sm"
        variant="simple"
        sx={{
          'td:first-child, th:first-child': {
            pl: 0,
          },
          'th,td': {
            borderColor: 'gray.200',
          },
          'tbody tr:last-child td': {
            borderWidth: 0,
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Status</Th>
            <Th>Phone number</Th>
            <Th>Error</Th>
            <Th>More info</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedAttempts.map((attempt, index) => (
            <Tr key={index}>
              <Td>
                <Flex align="center">
                  <Box mr="3">
                    {attempt.status === 'delivered' ? (
                      <ImCheckmark color={success} size="14" />
                    ) : (
                      <IoMdCloseCircle color={failure} size="14" />
                    )}
                  </Box>

                  {attempt.status}
                </Flex>
              </Td>
              <Td>{attempt.phoneNumber}</Td>
              <Td data-error-code={attempt.errorCode}>
                {attempt.errorCode &&
                  (ErrorCodeLookup[attempt.errorCode] || attempt.errorCode)}
              </Td>
              <Td>{attempt.errorMessage}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
