import { Trans, useTranslation } from 'react-i18next';
import { BsChatText } from 'react-icons/bs';
import { Image, Button, Icon, Link } from '@/ui';
import { EmptyState } from './EmptyState';
import mascot from './mascot-text.png';

type Props = {
  title?: string;
  onAddMessage: () => void;
};

export const MessagesEmptyState = ({ title, onAddMessage }: Props) => {
  const { t } = useTranslation('scheduleMessages');

  return (
    <EmptyState
      image={<Image alt="" maxW="341px" src={mascot} />}
      title={title ?? t('empty_state.scheduled_title')}
      byline={
        <Trans i18nKey="empty_state.scheduled_byline" t={t}>
          To add a text message, edit an item on the{' '}
          <Link onClick={onAddMessage}>schedule tab</Link>
          , and click on the <Icon
            as={BsChatText}
            pos="relative"
            top="2px"
          />{' '}
          icon (top right)
        </Trans>
      }
    >
      <Button onClick={onAddMessage}>{t('add_a_message')}</Button>
    </EmptyState>
  );
};
