import { Fragment } from 'react';
import { Box, Divider, Heading } from '@/ui';
import type { MessageGroup } from '../../hooks/useEntryMessages';
import { Message } from '../Message';

type Props = {
  showStatus?: boolean;
  data: MessageGroup[];
};

export const MessageList = ({ data, showStatus }: Props) => {
  return (
    <Box flex="1">
      {data.map(({ date, messages }, index) => {
        return (
          <Fragment key={date.toISODate()}>
            <Box>
              <Heading mb="8" size="md">
                {date.toFormat('MMMM d, yyyy')}
              </Heading>

              {messages.map((message, index) => (
                <Message
                  key={message.id}
                  mb={index === messages.length - 1 ? 0 : 10}
                  message={message}
                  showStatus={showStatus}
                />
              ))}
            </Box>

            {index < data.length - 1 && <Divider my="12" />}
          </Fragment>
        );
      })}
    </Box>
  );
};
