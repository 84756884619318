import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  DeliveryTimeType,
  MessageStatusType,
  SortDirection,
} from '@/types/gql.generated';
import { createEntryDate, dateInZone } from '@/utils/dates';
import { createScheduleEntryMessagesQueryKey } from '@/utils/queryKeys';
import type {
  GetScheduleEntryMessagesQuery,
  GetScheduleEntryMessagesQueryVariables,
} from './useEntryMessages.generated';

export type EntryMessage = Omit<
  GetScheduleEntryMessagesQuery['getEntryMessages'][number],
  'instance' | 'sendAt'
> & {
  instance: DateTime;
  sendAt: DateTime;
};

export type MessageGroup = {
  date: DateTime;
  messages: EntryMessage[];
};

type GroupedEntryMessages = {
  all: MessageGroup[];
  scheduled: MessageGroup[];
  sent: MessageGroup[];
};

const groupMessagesByDate = (messages: EntryMessage[]): MessageGroup[] => {
  const groups = messages.reduce<{
    [day: string]: MessageGroup;
  }>((acc, message) => {
    const day = message.sendAt.toISODate();

    if (!acc[day]) {
      acc[day] = {
        date: message.sendAt,
        messages: [],
      };
    }

    acc[day].messages.push(message);

    return acc;
  }, {});

  return Object.values(groups);
};

const query = gql`
  query GetScheduleEntryMessages(
    $scheduleId: ID!
    $sortDirection: SortDirection
  ) {
    getEntryMessages(scheduleId: $scheduleId, sortDirection: $sortDirection) {
      id
      instance
      body
      status
      deliveryTimeType
      hybridDeliveryTime
      hybridRelativeTime
      hybridTimeZone
      relativeTimeToEntryStart
      relativeTimeToEntryStartUnit
      relativeTimeToEntryStartDirection
      sendAt
      recipients {
        id
        name
      }
      entry {
        id
        title
        timeZone
        recurrences {
          isOnDay
        }
      }
      deliveryAttempts {
        status
        success
        phoneNumber
        errorCode
        errorMessage
      }
    }
  }
`;

export const useEntryMessages = () => {
  const { scheduleId, timeZone: scheduleTimeZone } = useScheduleContext();
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Desc
  );

  const selector = useCallback(
    (data: GetScheduleEntryMessagesQuery): GroupedEntryMessages => {
      const messages = data.getEntryMessages.map((message) => {
        const sendAt =
          message.deliveryTimeType === DeliveryTimeType.Relative
            ? dateInZone(
                message.sendAt,
                message.entry.timeZone,
                scheduleTimeZone
              )
            : DateTime.fromISO(message.sendAt, {
                zone: message.hybridTimeZone ?? scheduleTimeZone,
              });

        const instance = createEntryDate(
          message.instance,
          message.entry.timeZone,
          scheduleTimeZone,
          message.entry.recurrences[0].isOnDay
        );

        return {
          ...message,
          instance,
          sendAt,
        };
      });

      return {
        all: groupMessagesByDate(messages),
        scheduled: groupMessagesByDate(
          messages.filter(
            ({ status }) => status === MessageStatusType.Scheduled
          ) ?? []
        ),
        sent: groupMessagesByDate(
          messages.filter(({ status }) => status === MessageStatusType.Sent) ??
            []
        ),
      };
    },
    [scheduleTimeZone]
  );

  const result = useQuery({
    queryKey: createScheduleEntryMessagesQueryKey(scheduleId, sortDirection),
    select: selector,
    placeholderData: keepPreviousData,
    queryFn: () => {
      return gqlClient.request<
        GetScheduleEntryMessagesQuery,
        GetScheduleEntryMessagesQueryVariables
      >(query, {
        scheduleId,
        sortDirection,
      });
    },
  });

  return {
    ...result,
    sortDirection,
    setSortDirection,
  };
};
