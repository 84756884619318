import { useTranslation } from 'react-i18next';
import { Button, Flex, Image, Box, Text } from '@/ui';
import { EmptyState } from './EmptyState';
import mascot from './mascot.png';

type Props = {
  onAddClick: () => void;
};

export const RecipientsEmptyState = ({ onAddClick }: Props) => {
  const { t } = useTranslation('scheduleMessages', {
    keyPrefix: 'empty_state',
  });

  return (
    <EmptyState
      byline={t('recipient_byline')}
      image={<Image alt="" maxW="269px" mb="-4" src={mascot} />}
      title={t('recipient_title')}
    >
      <Flex
        direction="column"
        flex="1"
        gap="8"
        justify="space-between"
        pb="10"
        w="100%"
      >
        <Box>
          <Button onClick={onAddClick}>{t('recipient_add')}</Button>
        </Box>
        <Flex
          align="center"
          bg="gray.100"
          borderRadius="lg"
          gap="4"
          maxW="550px"
          mx="auto"
          p="4"
          textAlign="left"
          w="100%"
        >
          <Text fontSize="2xl">💡</Text>
          {t('recipient_hint')}
        </Flex>
      </Flex>
    </EmptyState>
  );
};
